<template>
  <div>
    <img
      src="@/assets/imgs/pdf-icon.png"
      alt="PDF Icon"
      width="20"
      height="24"
    />
  </div>
</template>
    
    <script>
/**
 * @fileoverview PDF Icon
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

export default {
  name: "PDFIcon",
};
</script>
    
<style lang="scss" scoped>
</style>